<script setup lang="ts">
import { useI18n, ref } from '#imports'

const { $t } = useI18n()
const { notifySuccess } = useNotification()

const links = [
  {
    label: 'Resources',
    children: [
      {
        label: 'Help center',
      },
      {
        label: 'Docs',
      },
      {
        label: 'Roadmap',
      },
      {
        label: 'Changelog',
      },
    ],
  },
  {
    label: 'Features',
    children: [
      {
        label: 'Affiliates',
      },
      {
        label: 'Portal',
      },
      {
        label: 'Jobs',
      },
      {
        label: 'Sponsors',
      },
    ],
  },
  {
    label: 'Company',
    children: [
      {
        label: 'About',
      },
      {
        label: 'Pricing',
      },
      {
        label: 'Careers',
      },
      {
        label: 'Blog',
      },
    ],
  },
]

const email = ref('')
const loading = ref(false)

function onSubmit() {
  loading.value = true

  setTimeout(() => {
    notifySuccess('Subscribed!', {
      description: 'You\'ve been subscribed to our newsletter.',
    })

    loading.value = false
  }, 1000)
}

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<template>
  <UFooter>
    <template #top>
      <UFooterColumns :links="links">
        <template #right>
          <form @submit.prevent="onSubmit">
            <UFormGroup
              label="Subscribe to our newsletter"
              :ui="{ container: 'mt-3' }"
            >
              <UInput
                v-model="email"
                type="email"
                placeholder="Enter your email"
                :ui="{ icon: { trailing: { pointer: '' } } }"
                required
                size="xl"
                autocomplete="off"
                class="max-w-sm"
              >
                <template #trailing>
                  <UButton
                    type="submit"
                    size="sm"
                    :label="loading ? 'Subscribing' : 'Subscribe'"
                    :loading="loading"
                  />
                </template>
              </UInput>
            </UFormGroup>
          </form>
        </template>
      </UFooterColumns>
    </template>

    <template #left>
      <p class="text-gray-500 dark:text-gray-400 text-sm">
        {{ $t('platformBy') }}
        <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
        <Anchor
          to="https://aac-digital.com"
          underline
        >
          AAC Digital
        </Anchor>
      </p>
    </template>

    <template #right>
      <div class="flex items-center space-x-4">
        <ThemeSwitcher />

        <LocaleSwitcher
          class="w-fit"
          @change="scrollToTop()"
        />
      </div>
    </template>
  </UFooter>
</template>
