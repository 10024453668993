<script setup lang="ts">
import type { NavItem } from '@nuxt/content'
import { useAuthStore } from '~~/layers/auth/app/stores/auth'
import { mapContentNavigation, ref } from '#imports'
import { type Ref, inject } from '#imports'

const authStore = useAuthStore()

const navigation = inject<Ref<NavItem[]>>('navigation', ref([]))
</script>

<template>
  <UHeader>
    <template #logo>
      Example Company
    </template>

    <template #right>
      <template v-if="authStore.isLoggedIn">
        <UButton
          label="My account"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          color="black"
          to="/platform"
          class="hidden lg:flex"
        />
      </template>

      <template v-else>
        <UButton
          label="Sign in"
          color="gray"
          to="/auth/login"
        />

        <UButton
          label="Sign up"
          icon="i-heroicons-arrow-right-20-solid"
          trailing
          color="black"
          to="/auth/register"
          class="hidden lg:flex"
        />
      </template>
    </template>

    <template #panel>
      <UNavigationTree
        :links="mapContentNavigation(navigation)"
        default-open
      />
    </template>
  </UHeader>
</template>
